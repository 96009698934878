import React from 'react';
import './Styles.css';

export default function NotFound() {

  return (
    <div className="full-page-container mt-5">
      <h1>No such page exists!</h1>
    </div>
  );
}