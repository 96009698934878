import React from 'react';
import './Styles.css';

export default function Footer() {
  return (
    <div className="">
      {/* <div className="full-page-container"></div> */}
      <footer className="card-footer alert-light">
        <small>Made with ♥ & ♫ by Moog (me).</small>
      </footer>
    </div>
  );
}